/*
	Author: Giuliano Salvatore
	web: http://www.giulianosalvatore.com
	twitter: @salvogiuliano 
*/

/* ====================================================
   	START CSS LIS-CONNECT | Author: Salvatore Giuliano
   ====================================================*/

/* | GENERALE |
===================================================== */

body {
  background-color: #fff;
  color: #242424;
  font-size: 62.5%;
  font-family: 'DroidSansRegular', Helvetica, Arial, sans-serif;
}

.hidden {
  display: none;
  visibility: hidden;
}
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
.invisible {
  visibility: hidden;
}

.ir {
  display: block;
  border: 0;
  text-indent: -999em;
  overflow: hidden;
  background-color: transparent;
  background-repeat: no-repeat;
  text-align: left;
  direction: ltr;
  *line-height: 0;
}
.ir br {
  display: none;
}

.left {
  float: left;
}
.right {
  float: right;
}

.clear:before,
.clear:after {
  content: '';
  display: table;
}
.clear:after {
  clear: both;
}
.clear {
  *zoom: 1;
}

input[type='button'],
input[type='submit'],
input[type='image'] {
  cursor: pointer;
}
::-webkit-input-placeholder {
  font-style: italic;
}
::-moz-placeholder {
  font-style: italic;
}

/* link */
a {
}

/* testo */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}
h1 {
  color: #041122;
  font: normal 3.2em / normal 'TitilliumText25L600wt', Helvetica, Arial, sans-serif;
}
h2 {
  color: #041122;
  font: normal 2.4em / normal 'TitilliumText25L400wt', helvetica, Arial, sans-serif;
}
h3 {
}
h4 {
  font: normal 1.6em / normal 'TitilliumText25L400wt', helvetica, Arial, sans-serif;
}
h5 {
}
h6 {
}
b,
strong,
th {
  font-weight: bold;
}

#wrapper {
  width: 100%;
}
.icon {
  position: absolute;
  top: 50%;
  left: 50%;
  background-image: url(assets/images/sprite.png);
  background-repeat: no-repeat;
}

.divider {
  margin: 2em 0 3em;
  border-bottom: solid 1px #bdcddd;
}
.divider h3 {
  position: relative;
  top: 10px;
  float: left;
  padding: 1em 1em 0 0;
  background-color: #fff;
}

/* | HEADER |
===================================================== */
.wpHeader {
  width: 100%;
  background: #102f56;
  background: -moz-linear-gradient(top, #102f56 0%, #041122 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #102f56), color-stop(100%, #041122));
  background: -webkit-linear-gradient(top, #102f56 0%, #041122 100%);
  background: -o-linear-gradient(top, #102f56 0%, #041122 100%);
  background: -ms-linear-gradient(top, #102f56 0%, #041122 100%);
  background: linear-gradient(to top, #102f56 0%, #041122 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102f56', endColorstr='#041122',GradientType=0 );
}

.headerSed {
}
.headerSed .logo {
  display: block;
  width: 200px;
  height: 53px;
  float: left;
  background: transparent url(assets/images/sprite.png) no-repeat 0 0;
  text-indent: -9999px;
}
.headerSed nav {
  float: right;
}
.headerSed nav ul {
  padding: 0.5em 0 0;
}
.headerSed nav ul li {
  display: inline;
  float: left;
}
.headerSed nav ul li h3 {
  font: normal 1.4em / normal 'TitilliumText25L250wt', Helvetica, Arial, sans-serif;
}
.headerSed nav ul li a {
  display: block;
  padding: 1em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  font-size: 12px;
}
.headerSed nav ul li a:hover,
.headerSed nav ul li.active a,
.headerSed nav ul li.current-menu-item a,
.headerSed nav ul li.current_page_item a {
  color: #e5cf16;
}
.headerSed nav ul li.current-menu-item a,
.headerSed nav ul li.current_page_item a {
  cursor: default;
}

/* | SLIDE HOME |
===================================================== */
.wpSlideHome {
  position: relative;
  width: 100%;
  background: #004f92;
  background: -moz-linear-gradient(top, #001d35 0%, #004f92 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #001d35), color-stop(100%, #004f92));
  background: -webkit-linear-gradient(top, #001d35 0%, #004f92 100%);
  background: -o-linear-gradient(top, #001d35 0%, #004f92 100%);
  background: -ms-linear-gradient(top, #001d35 0%, #004f92 100%);
  background: linear-gradient(to top, #001d35 0%, #004f92 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#001d35', endColorstr='#004f92',GradientType=0 );
}
.slideHome {
}
.slideHome ul {
}
.slideHome ul li {
  display: inline;
  width: 100%;
  float: left;
}
.slideHome ul li article {
  width: 100%;
  overflow: hidden;
}
.slideHome ul li article figure {
  position: relative;
  width: 100%;
  height: 0;
  padding: 0 0 31%;
  overflow: hidden;
}
.slideHome ul li article figure img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}

.logoLottomatica {
  position: absolute;
  bottom: -10px;
  left: 50%;
  z-index: 10;
  width: 200px;
  height: 40px;
  margin: 0 0 0 -100px;
  background: #fff url(assets/images/logo_lottomatica.jpg) no-repeat 0 0;
  background-size: 192px 37px;
  text-indent: -999px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}

/* | CONTENT |
===================================================== */
.wpConten {
  width: 100%;
  background: transparent url(assets/images/pattern.png) repeat-x 0 0;
}

/* | main | */
.main {
  width: 100%;
}
.main header {
  margin: 0 0 2em;
}
.main h3 {
  color: #041122;
  font: italic 1.8em / normal 'TitilliumText25L400wt', helvetica, Arial, sans-serif;
}
.main p {
  margin: 1.5em 0 0;
  font: normal 1.4em/1.8 'DroidSansRegular', Helvetica, Arial, sans-serif;
}
.main p:first-child {
  margin: 0;
}

.sidebar-sx .main {
  width: 72%;
  float: right;
}
.doveSiamo p {
  width: 30%;
  float: left;
}

/* | intro | */
.intro {
  width: 100%;
}
.intro h3 {
}

/* | box home | */
.boxHome {
  width: 100%;
  overflow: hidden;
}
.boxHome li {
  display: inline;
  float: right;
  width: 48%;
}
.boxHome li:first-child {
  float: left;
}
.boxHome li article {
  padding: 2em 2.5em;
  background: transparent url(assets/images/pattern.png) repeat-x 0 0;
  border: solid 1px #bdcddd;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
}
.boxHome li article header {
  position: relative;
  padding: 8em 0 0;
}
.istallazione .icon {
  top: 0;
  width: 60px;
  height: 60px;
  margin: 0 0 0 -30px;
  background-position: -79px -112px;
}
.progettazione .icon {
  top: 0;
  width: 58px;
  height: 56px;
  margin: 0 0 0 -29px;
  background-position: -2px -112px;
}

.post {
  width: 100%;
}
.post .w100 {
  width: 45%;
  float: right;
  padding: 0 0 0 20px;
}
.post .w100 img {
  width: 100%;
  height: auto;
}
.post ol {
  padding: 0 0 0 1.5em;
}
.post ol > li {
  margin: 0 0 0.8em;
  list-style: decimal;
  font: normal 1.5em/1.8 'DroidSansRegular', Helvetica, Arial, sans-serif;
}

.post ol > li > ol > li {
  position: relative;
  list-style: none;
  margin: 0 0 0.8em;
}
.post ol .number {
  position: absolute;
  left: -1.8em;
}
.numberList {
  font: normal 1.5em/1.8 'DroidSansRegular', Helvetica, Arial, sans-serif;
}
.numberList p {
  font: normal 1em/1.8 'DroidSansRegular', Helvetica, Arial, sans-serif;
}

.faq {
  width: 75%;
  float: right;
}

/* sidebar */
.sidebar {
  width: 24%;
}
.sidebar .widget {
  margin: 0 0 1em;
}
.sidebar .widget h4 {
  margin: 0 0 1em;
  font: normal 1.8em / normal 'TitilliumText25L600wt', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}

.menuSidebar {
  width: 100%;
  overflow: hidden;
  border: solid 1px #d4d4d4;
  border-top: solid 1px #f1f1f1;
  border-left: solid 1px #eee;
}
.menuSidebar ul {
}
.menuSidebar ul li {
  border-top: solid 1px #ddd;
}
.menuSidebar ul li:first-child {
  border: none;
}
.menuSidebar ul li h3 {
  font: normal 1.3em / normal 'TitilliumText25L600wt', Helvetica, Arial, sans-serif;
  text-transform: uppercase;
}
.menuSidebar ul li h3 a {
  display: block;
  padding: 0.8em 1em;
  color: #2e517b;
  text-decoration: none;
}
.menuSidebar ul li h3 a:hover {
  background: transparent url(assets/images/pattern.png) repeat 0 0;
  color: #242424;
}
/* | FOOTER |
===================================================== */
.wpFooter {
  width: 100%;
  background: #102f56;
  background: -moz-linear-gradient(top, #102f56 0%, #041122 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #102f56), color-stop(100%, #041122));
  background: -webkit-linear-gradient(top, #102f56 0%, #041122 100%);
  background: -o-linear-gradient(top, #102f56 0%, #041122 100%);
  background: -ms-linear-gradient(top, #102f56 0%, #041122 100%);
  background: linear-gradient(to top, #041122 0%, #102f56 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#102f56', endColorstr='#041122',GradientType=0 );
}

.footerSed {
}
.footerSed .logo {
  display: block;
  width: 120px;
  height: 29px;
  float: left;
  margin: 0.8em 0 0;
  background: transparent url(assets/images/sprite.png) no-repeat 0 -64px;
  text-indent: -9999px;
}
.footerSed nav {
  float: right;
}
.footerSed nav ul {
  overflow: hidden;
}
.footerSed nav ul li {
  display: inline;
  float: left;
}
.footerSed nav ul li h3 {
  font: normal 1.4em / normal 'TitilliumText25L250wt', Helvetica, Arial, sans-serif;
}
.footerSed nav ul li h3 a {
  display: block;
  padding: 1em;
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}
.footerSed nav ul li a:hover,
.footerSed nav ul li.current-menu-item a,
.footerSed nav ul li.current_page_item a {
  color: #e5cf16;
}
.footerSed nav ul li.current-menu-item a,
.footerSed nav ul li.current_page_item a {
  cursor: default;
}

/* | crediti | */
.wpCredits {
  width: 100%;
  background-color: #fff;
  overflow: hidden;
}
.credits {
}
.credits p {
  float: left;
  padding: 0.7em 0 0;
  font: normal 1.2em / normal 'DroidSansRegular', Helvetica, Arial, sans-serif;
}

/* | social | */
.social {
  float: right;
  overflow: hidden;
}
.social li {
  display: inline;
  float: left;
  margin: 0 0 0 1em;
}
.social li a {
  display: block;
  width: 30px;
  height: 30px;
  background-image: url(assets/images/sprite.png);
  background-repeat: no-repeat;
  text-indent: -9999px;
}
.social li a.twitter {
  background-position: 0 -184px;
}
.social li a.twitter:hover {
  background-position: 0 -223px;
}
.social li a.facebook {
  background-position: -41px -184px;
}
.social li a.facebook:hover {
  background-position: -41px -223px;
}
.social li a.googlePlus {
  background-position: -83px -185px;
}
.social li a.googlePlus:hover {
  background-position: -83px -224px;
}

/* accordion */
.accordionTitle {
  position: relative;
  margin: 1em 0 0;
  padding: 1em;
  background-color: #f0f0f0;
  border: solid 1px #d4d4d4;
  border-top: solid 1px #f1f1f1;
  border-left: solid 1px #eee;
  cursor: pointer;
}
.accordionTitle h4 {
  margin: 0 0 0.8em;
  padding: 0 2em 0 0;
}
.accordionTitle span {
  font-style: italic;
}
.accordionTitle ul {
  padding: 0 0 1em 1.5em;
  list-style: square;
  font: normal 1.2em/1.4 'DroidSansRegular', Helvetica, Arial, sans-serif;
}
.accordionTitle .icon {
  top: 1.3em;
  left: auto;
  right: 0.8em;
  width: 10px;
  height: 10px;
  background-position: -167px -191px;
}
.accordionTitle.active .icon {
  background-position: -182px -191px;
}

.accordionBox {
  padding: 1em;
  background-color: #fff;
  border: solid 1px #d4d4d4;
  border-top: solid 1px #f1f1f1;
  border-left: solid 1px #eee;
}

.document {
  padding: 0 1em;
  overflow: hidden;
}
.document li {
  position: relative;
  padding: 0.8em 0 0.8em 3.8em;
}
.document li a {
  color: #242424;
  font: italic 1.5em/1.4 'TitilliumText25L400wt', Helvetica, Arial, sans-serif;
  text-decoration: none;
}
.document li a:hover {
  text-decoration: underline;
}
.document li .icon {
  top: 0.3em;
  left: 0;
  width: 24px;
  height: 30px;
  background-position: -131px -187px;
}

/* map */
.map {
  position: relative;
  width: 100%;
  border-top: solid 1px #fff;
}

/* | FORM |
===================================================== */
.wpcf7 {
  width: 64%;
  float: right;
}
.wpcf7 .wpcf7-text {
  width: 98%;
  padding: 0.5em 1em;
  border: solid 1px #ccc;
  box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.wpcf7 .wpcf7-textarea {
  width: 98%;
  padding: 0.5em 1em;
  border: solid 1px #ccc;
  box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  -moz-box-shadow: inset 1px 1px 6px 0 rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.wpcf7 .wpcf7-submit {
  padding: 0.6em 1em;
  background-color: #102f56;
  color: #fff;
  border: none;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
}

.wpcf7 p {
  width: 100%;
}

/* | MEDIA QUERY |
===================================================== */

@media only screen and (min-width: 1224px) {
  .headerSed {
    width: 960px;
    padding: 2em 0;
    margin: 0 auto;
  }
  .slideHome {
    width: 960px;
    margin: 0 auto;
    overflow: hidden;
  }

  .content {
    width: 960px;
    margin: 0 auto;
    padding: 0 0;
  }

  .text-content {
    width: 960px;
    margin: 0 auto;
    padding: 5em 0;
  }

  .footerSed {
    width: 960px;
    margin: 0 auto;
    padding: 2em 0;
  }
  .credits {
    width: 960px;
    margin: 0 auto;
    padding: 2em 0;
    overflow: hidden;
  }
}

@media only screen and (max-width: 1280px) {
  .headerSed {
    width: 90%;
    padding: 2em 0;
    margin: 0 auto;
  }
  .slideHome {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding: 6em 0;
  }
  .footerSed {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
  }
  .credits {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
    overflow: hidden;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .headerSed {
    width: 90%;
    padding: 2em 0;
    margin: 0 auto;
  }
  .slideHome {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding: 6em 0;
  }
  .footerSed {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
  }
  .credits {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
    overflow: hidden;
  }
}

@media only screen and (max-width: 800px) {
  .headerSed {
    width: 90%;
    padding: 2em 0;
    margin: 0 auto;
  }
  .slideHome {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding: 6em 0;
  }
  .footerSed {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
  }
  .credits {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
    overflow: hidden;
  }
}

@media only screen and (max-width: 480px) {
  .headerSed {
    width: 90%;
    padding: 2em 0;
    margin: 0 auto;
  }
  .headerSed .logo {
    width: 200px;
    height: 53px;
    float: none;
    margin: 0 auto;
  }
  .slideHome {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding: 6em 0;
  }
  .footerSed {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
  }
  .credits {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
    overflow: hidden;
  }
  .boxHome li {
    display: block;
    width: 100%;
    float: none;
    margin: 1em 0 0;
  }
  .boxHome li:first-child {
    float: none;
    margin: 0;
  }
  .sidebar-sx .main {
    width: 100%;
    float: none;
  }
  .sidebar {
    width: 100%;
  }
  .footerSed .logo {
    width: 120px;
    height: 29px;
    float: none;
    margin: 0 auto;
  }
}

@media only screen and (max-width: 320px) {
  .headerSed {
    width: 90%;
    padding: 2em 0;
    margin: 0 auto;
  }
  .headerSed .logo {
    width: 200px;
    height: 53px;
    float: none;
    margin: 0 auto;
  }
  .slideHome {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
  }
  .content {
    width: 90%;
    margin: 0 auto;
    padding: 6em 0;
  }
  .footerSed {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
  }
  .credits {
    width: 90%;
    margin: 0 auto;
    padding: 2em 0;
    overflow: hidden;
  }
  .credits p {
    width: 41%;
    padding: 0;
  }
  .boxHome li {
    display: block;
    width: 100%;
    float: none;
    margin: 1em 0 0;
  }
  .boxHome li:first-child {
    float: none;
    margin: 0;
  }
  .sidebar-sx .main {
    width: 100%;
    float: none;
  }
  .sidebar {
    width: 100%;
  }
  .footerSed .logo {
    width: 120px;
    height: 29px;
    float: none;
    margin: 0 auto;
  }
}

/* box per IE6
============================================*/
.chromeframe {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0;
}

/* | PRINT |
===================================================== */
@media print {
  * {
    background: transparent !important;
    color: black !important;
    box-shadow: none !important;
    text-shadow: none !important;
    filter: none !important;
    -ms-filter: none !important;
  } /* Black prints faster: h5bp.com/s */
  a,
  a:visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: ' (' attr(href) ')';
  }
  abbr[title]:after {
    content: ' (' attr(title) ')';
  }
  .ir a:after,
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  } /* Don't show links for images, or javascript/internal links */
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  } /* h5bp.com/t */
  tr,
  img {
    page-break-inside: avoid;
  }
  img {
    max-width: 100% !important;
  }
  @page {
    margin: 0.5cm;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
}

/* ====================================================
   	END CSS LIS-CONNECT | twitter: @salvogiuliano 
   ====================================================*/
.bottomBoxHome {
  margin-top: 15px;
  display: flex;
  width: 100%;
  overflow: hidden;
  padding: 10px;
}

.ad {
  float: left;
}
.ad img {
  width: 200px;
}

.cit {
  display: block;
}

.cit h3 {
  margin-top: 5px;
  display: block;
  margin-left: 30px;
  font-size: x-large;
  font-style: normal;
}

.cit p {
  margin: 0;
  display: block;
  margin-left: 30px;
}

agm-map {
  height: 200px;
}

.navbar {
  text-transform: uppercase;
  width: 960px;
  margin: 0 auto;
}

.navbar nav ul li {
  float: left;
  padding: 0 10px;
}

.navbar nav ul li a {
  display: block;
  padding: 0;
  color: #000;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
}

.navbar nav ul li a:hover {
  color: #e5cf16;
}

.offering {
  width: 100%;
  display: block;
  text-align: center;
}

.offering table {
  margin: 0 auto;
}

.offering_image img {
  width: 600px;
  margin: 0 auto;
}

.lis_wifi img {
  width: 200px;
  margin: 20px auto;
}
.products ul {
  margin: 50px 20px;
}
.products ul li {
  background-image: url('assets/images/arrow.png');
  background-size: 69px;
  background-repeat: no-repeat;
  padding: 48px 0 0 80px;
  margin: -24px 0;
  font-size: large;
}

.products ul li a {
  display: block;
  padding: 0;
  color: #000;
  text-transform: uppercase;
  text-decoration: none;
}

.home-page header {
  color: #2e4057;
}

.home-page header h1 {
  color: #2e4057;
  width: 100%;
  text-align: center;
  font-style: oblique;
  font-weight: bolder;
  font-size: 3em;
}

.home-page header p {
  width: 100%;
  text-align: center;
  font-size: 2.8em;
  margin: 0;
}

.banner {
  background-image: url('assets/images/home_banner.png');
  background-size: 700px;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
}
